<template>

    <div class="renewStatistics">

        <!-- 表格 -->
        <div class="search">
            <a-tree-select
                class="search-style"
                style="width: 250px"
                v-model="OrgIds" :tree-data="treeData" tree-checkable :maxTagCount="1" placeholder="请选择搜索范围" @change="change" :show-checked-strategy="SHOW_PARENT"/>

            <a-range-picker style="width: 250px;margin-right:10px" v-model="selectDate" @change="_dateChange"/>


            <a-button class="search-style" @click="_clear">重置</a-button>
            <a-button type="primary" @click="_search">查询</a-button>
        </div>


        <!-- 折线图 -->
        <div id="container" style="margin-bottom:20px"></div>
        <!---->

        <div :style="'width:' +($store.state.app.showNav ? pageWidth*0.95 : pageWidth) +'px;overflow: auto'">
            <a-table
                :pagination="false"
                :columns="columns"
                bordered
                :data-source="tableData"
            >
            </a-table>
        </div>
    </div>
    </div>
</template>

<script>
import moment from 'moment'
import elementResizeDetectorMaker from 'element-resize-detector'
import {mapState} from 'vuex'
import G2 from '@antv/g2'

export default {
    name: 'renewStatistics',
    data() {
        return {
            userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
            columns: [
                {
                    title: '组织架构',
                    dataIndex: 'OrgName',
                    key: 'OrgName',
                    align: 'left',
                    width: 220,
                    // fixed: 'left',
                    ellipsis: true
                },
                {
                    title: '总会员数',
                    dataIndex: 'All',
                    key: 'All',
                    align: 'left',
                    width: 120,
                    // fixed: 'left',
                    ellipsis: true
                },
                {
                    title: '活跃会员',
                    dataIndex: 'Active',
                    key: 'Active',
                    align: 'left',
                    width: 120,
                    // fixed: 'left',
                    ellipsis: true
                },
                {
                    title: '未跟进续费意向',
                    dataIndex: 'NoStatus',
                    key: 'NoStatus',
                    align: 'left',
                    width: 140,
                    // fixed: 'left',
                    ellipsis: true
                },
                {
                    title: '已跟进',
                    dataIndex: 'HaveStatus',
                    key: 'HaveStatus',
                    align: 'left',
                    width: 120,
                    // fixed: 'left',
                    ellipsis: true
                },
                {
                    title: '已续费',
                    dataIndex: 'StatusE',
                    key: 'StatusE',
                    align: 'left',
                    width: 120,
                    // fixed: 'left',
                    ellipsis: true
                },
                {
                    title: '续费意向A',
                    dataIndex: 'StatusA',
                    key: 'StatusA',
                    align: 'left',
                    width: 120,
                    // fixed: 'left',
                    ellipsis: true
                },
                {
                    title: '续费意向B',
                    dataIndex: 'StatusB',
                    key: 'StatusB',
                    align: 'left',
                    width: 120,
                    // fixed: 'left',
                    ellipsis: true
                },
                {
                    title: '续费意向C',
                    dataIndex: 'StatusC',
                    key: 'StatusC',
                    align: 'left',
                    width: 120,
                    // fixed: 'left',
                    ellipsis: true
                },
                {
                    title: '续费意向D',
                    dataIndex: 'StatusD',
                    key: 'StatusD',
                    align: 'left',
                    width: 120,
                    // fixed: 'left',
                    ellipsis: true
                }
            ],
            line: '',
            data: [],
            treeData: [],
            pageWidth: '',
            OrgIds: [],
            OrgName: [],
            ParentId: [],
            SHOW_PARENT: 'SHOW_ALL',
            tableData: [],
            selectDate: [],
        }
    },
    computed: {
        ...mapState({
            showNav: state => state.app.showNav
        })
    },

    mounted() {
        const that = this
        window.onresize = () => {
            return (() => {
                window.fullWidth = document.documentElement.clientWidth
                that.fullWidth = window.fullWidth
            })()
        }
    },
    watch: {
        /* 监听*/
        fullWidth(val) {
            document.getElementById('container').innerHTML = ' '
            this.containerTable(this.line)
        }
    },

    created() {
        this.pageWidth = document.documentElement.clientWidth - 220
        this._treeData()
        this._tableList()
    },
    methods: {
        moment,


        _treeData() {
            let self = this
            this.$axios.get(
                1090,
                {
                    UserId: self.userinfo.uid
                },
                res => {
                    self.treeData = res.data
                }
            )
        },
        _tableList() {
            let self = this
            this.$message.loading('加载中...')
            this.$axios.post(
                8000126,
                {
                    OrgIds: self.OrgIds.length > 0 ? JSON.stringify(self.OrgIds) : '',
                    StartDate: this.selectDate.length == 0 ? '' : this.selectDate[0],
                    EndDate: this.selectDate.length == 0 ? '' : this.selectDate[1],
                    // OrgName: self.OrgName.length > 0 ? JSON.stringify(self.OrgName) : '',
                },
                res => {
                    this.$message.destroy()
                    if (res.data.code == 1) {
                        self.tableData = res.data.data
                        let line = res.data.line
                        self.line = line
                        setTimeout(function () {
                            document.getElementById('container').innerHTML = ' '
                            self.containerTable(line)
                        }, 400)
                    } else {
                        self.tableData = []
                        this.$message.error(res.data.msg)
                    }
                }
            )
        },

        //曲线图
        containerTable(line) {
            let data = line;
            this.containerWidth = document.getElementById('container').clientWidth;
            const chart = new G2.Chart({
                container: 'container',
                autoFit: true,
                height: 420,
                width: this.containerWidth,
                padding: [20, 100, 30, 60]
            })

            chart.source(data, {
                Date: {
                    alias: '日期',
                },
            })

            chart.tooltip({
                showCrosshairs: true,
                itemTpl:
                    '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
                shared: true,
            });
            chart.line().position('Date*Value').color('Type')
            // .shape('dash');
            chart.render();

        },

        change(value, label, text) {
            if (text.checked) {
                this.ParentId.push(text.triggerValue)
            } else {
                if (this.ParentId.length > 0) {
                    for (var i = 0; i < this.ParentId.length; i++) {
                        if (text.triggerValue == this.ParentId[i]) {
                            this.ParentId.splice(i, 1)
                        }
                    }
                }
            }

            this.OrgName = label
            this.OrgIds = value
        },
        handleChange(value) {
            this.classTypeId = value
        },
        _search() {
            this._tableList()
        },
        _clear() {
            this.OrgIds = []
            this.OrgName = []
            this.selectDate = []
        },
        _dateChange(date){
            this.selectDate = [this.__moment__(date[0]).format('YYYY-MM-DD'),this.__moment__(date[1]).format('YYYY-MM-DD')];
        },
    }
}
</script>

<style scoped lang="less">
.renewStatistics {
    background: #ffffff;
    margin-top: 15px;
    min-height: 800px;
    padding: 15px;
}

.search {
    margin-bottom: 20px;
    display: flex;

    .search-style {
        /*display: inline-block;*/
        width: 120px;
        margin-right: 15px;
    }
}

.renewStatistics .ant-table-tbody > tr > td {
    padding: 0 !important;
}

.tableData {
    margin-top: 50px;
}
</style>
